.select-anno {
  margin-bottom: 25px;
}

.select-anno label {
  margin-right: 20px;
}

.select-anno .form-control {
  display: inline-block;
  width: auto;
}
