.avviso-pagamento h2 {
    margin-bottom: 20px;
}

.avviso-pagamento table {
    margin-bottom: 30px;
}

.avviso-pagamento table td {
    vertical-align: baseline;
}

.avviso-pagamento .value {
    padding-left: 20px;
    font-size: 30px;
    font-weight: 600;
}

.avviso-pagamento .bolletta-servizio {
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: .25rem;
    margin-bottom: 25px;
}

.avviso-pagamento address {
    padding-left: 15px;
    border-left: 4px solid hsl(202, 76%, 60%);
    margin-bottom: 30px;
}
