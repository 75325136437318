body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.select-anno {
  margin-bottom: 25px;
}

.select-anno label {
  margin-right: 20px;
}

.select-anno .form-control {
  display: inline-block;
  width: auto;
}

.tabella-bollette td {
    vertical-align: middle;
}
header {
    margin-bottom: 40px;
    background-color: hsl(202, 76%, 87%);
}
.dettaglio-bolletta .nav-tabs {
    margin-bottom: 30px;
}

.nav-tabs .nav-link {
  cursor: pointer;
}

.avviso-pagamento h2 {
    margin-bottom: 20px;
}

.avviso-pagamento table {
    margin-bottom: 30px;
}

.avviso-pagamento table td {
    vertical-align: baseline;
}

.avviso-pagamento .value {
    padding-left: 20px;
    font-size: 30px;
    font-weight: 600;
}

.avviso-pagamento .bolletta-servizio {
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: .25rem;
    margin-bottom: 25px;
}

.avviso-pagamento address {
    padding-left: 15px;
    border-left: 4px solid hsl(202, 76%, 60%);
    margin-bottom: 30px;
}

.stato-pagamento p:first-child {
    font-size: 1.5rem;
    font-weight: 600;
}
.presenze table th,
.presenze table td {
  text-align: center;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

